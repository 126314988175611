<!-- <section id="highlight" [ngClass]="{'invert-colors': invertColors}">
    <div class="highlight">
        <div class="highlight__columns">
            <div class="highlight__columns__item" [ngClass]="{'no-image': img === '' || !img}">
                <div class="highlight__info" [ngClass]="{'no-image-text': img === '' || !img}">
                    <div class="highlight__texts">
                        <h2 *ngIf="title" class="highlight__title" [innerHTML]="title | htmlParse"></h2>
                        <h3 *ngIf="description" class="highlight__subtitle" [innerHTML]="description | htmlParse"></h3>
                        <div *ngIf="disclaimer" class='highlight__disclaimer' [innerHTML]="disclaimer | htmlParse"></div>
                    </div>
                    <div *ngIf="img !== ''" style="width: 100%;">
                        <app-image class="highlight__banner" [path]="img" [alt]="alt_text" title="banner"></app-image>
                    </div>
                </div>
            </div>
            <div id="quote-form" class="highlight__columns__item other-bg">
                <div class="content">
                    <div class="wrapper-form">
                        <div *ngIf="text" class="highlight__title__form" [innerHTML]="text | htmlParse"></div>
                        <ng-content select="[form]"></ng-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section>
    <div class="olympic-section">

        <div class="olympic-section-container">
            <div class="row">
                <div class="col-12 col-xl-6">
                    <figure>
                        <img src="/assets/images/logo-olimpiadas.svg" alt="">
                    </figure>
                </div>
                <div class="col-12 col-xl-6">
                    <p>
                        Os <strong>Jogos Olímpicos Paris 2024</strong>
                        estão chegando e temos muito orgulho
                        em sermos parceira global dos
                        <strong>
                            <span>
                                Movimentos Olímpicos
                            </span>
                        </strong>
                        <strong>
                            <span>
                                e Paralímpicos
                            </span>
                        </strong>
                    </p>
                </div>
            </div>

            <a href="https://www.allianz.com.br/Blog/2024/olimpiadas-de-Paris.html" target="_blank" class="olympic-saiba-mais-btn">
                Saiba mais
            </a>
        </div>
    </div>
</section>
