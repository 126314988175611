<div class="home">
    <div *ngFor="let component of data let i = index">
        <!-- home -->
        <div *ngIf="component.handle == 'block_section'" [id]='component.fragment ? component.fragment : ""'>
            <section class="safe-description">
                <div class="wrapper-description">
                    <div class="residence">
                        <div class="wrapper-container">
                            <h2 [innerHTML]="component.data.text | htmlParse"></h2>
                            <h3 [innerHTML]="component.data.description | htmlParse"></h3>
                            <app-carousel-benefits [data]="component.data.blocks"></app-carousel-benefits>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- end home -->

        <!-- form -->
        <div *ngIf="component.handle == 'form_section'">
            <app-banner-form [title]="component.data.text" [description]="component.data.description" [disclaimer]="component.data.disclaimer_title" [text]="(slug === 'auto' && step === 3 && fullRequest) || (slug !== 'auto' && step === 2 && fullRequest) ? formFeedback.title_form : component.data.form.text" [img]="component.data.image"  [alt_text]="component.data.alt_text">
                <div form>
                    <form action='#'
                          autocomplete='off'
                          (ngSubmit)='onSubmit()'
                          [formGroup]='form'
                          class="formCustom">
                        <input type="hidden" name="hubUtmz" id="hubUtmz" value="" />
                        <div *ngIf="component.data.form.steps.length > 1" class="progress-wrap">
                            <ul class="checkout-bar">
                                <li class="progressbar-dots" *ngFor="let steps of component.data.form.steps let i = index" [ngClass]="{'active': steps.order === step, 'complete': steps.order < step, 'last': component.data.form.steps.length === i+1}">
                                    <div *ngIf="component.data.form.steps.length > i+1 || steps > 1" class="line-progress-bar" [ngClass]="{'active': steps.order === step, 'complete': steps.order < step }"></div>
                                    <span>{{steps.order}}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="wrapper-steps">
                            <div *ngFor="let steps of component.data.form.steps let i = index" class="wrapper-steps__step" [ngClass]="steps.order === step && !fullRequest ? 'show' : 'hide'" id="'step' + steps.order" >
                                <div class="form-content">
                                    <div *ngFor="let input of steps.inputs let i = index" >
                                        <div class="field" *ngIf="input.component === 'name'">
                                            <app-input
                                                [id]='input.component'
                                                [name]='input.component'
                                                [labelName]='this.helpers.removeTags(input.placeholder)'
                                                [placeholder]='this.helpers.removeTags(input.placeholder)'
                                                [form]='form'
                                            ></app-input>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'companyName'">
                                            <app-input
                                                [id]='input.component'
                                                [name]='input.component'
                                                [labelName]='this.helpers.removeTags(input.placeholder)'
                                                [placeholder]='this.helpers.removeTags(input.placeholder)'
                                                [form]='form'
                                            ></app-input>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'registrationNumber'">
                                            <p class='warning' *ngIf="steps.order === 3">Agora só falta seu CPF para encaminharmos sua solicitação!</p>

                                            <div [ngClass]="steps.order === 3 ? 'cpf' : ''">
                                                <app-input
                                                    [id]='input.component'
                                                    [name]='input.component'
                                                    [labelName]='input.placeholder'
                                                    [placeholder]='input.placeholder'
                                                    [form]='form'
                                                    inputMask='000.000.000-00'
                                                ></app-input>

                                                <span
                                                    *ngIf="steps.order === 3"
                                                    class="custom-tooltip btn-open"
                                                    (click)="openModalRegistration()"
                                                >i</span>
                                            </div>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'registrationNumberCFPorCNPJ'">
                                            <p class='warning' *ngIf="steps.order === 3">Agora só falta seu CPF para encaminharmos sua solicitação!</p>

                                            <div [ngClass]="steps.order === 3 ? 'cpf' : ''">
                                                <app-input
                                                    [id]='input.component'
                                                    [name]='input.component'
                                                    [labelName]='this.helpers.removeTags(input.placeholder)'
                                                    [placeholder]='this.helpers.removeTags(input.placeholder)'
                                                    [form]='form'
                                                    inputMask='000.000.000-00'
                                                    [inputAltMask]='{mask: "00.000.000/0000-00", digits: 10, oldMask: "000.000.000-00"}'
                                                ></app-input>

                                                <span
                                                    *ngIf="steps.order === 3"
                                                    class="custom-tooltip btn-open"
                                                    (click)="openModalRegistration()"
                                                >i</span>
                                            </div>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'email'">
                                            <app-input
                                                [id]='input.component'
                                                [name]='input.component'
                                                [labelName]='input.placeholder'
                                                [placeholder]='input.placeholder'
                                                typeInput='email'
                                                [form]='form'
                                            ></app-input>
                                            <span
                                                *ngIf='!changeEmail(form.get("email")?.value)'
                                                class='control-error diferent-email'
                                            >
                                            INSIRA UM EMAIL VÁLIDO
                                            </span>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'confirm_email'">
                                            <app-input
                                                [id]='input.component'
                                                [name]='input.component'
                                                [labelName]='input.placeholder'
                                                [placeholder]='input.placeholder'
                                                typeInput='email'
                                                [form]='form'
                                            ></app-input>
                                            <span
                                                *ngIf='!changeEmail(form.get("confirm_email")?.value)'
                                                class='control-error diferent-email'
                                            >
                                            INSIRA UM EMAIL VÁLIDO
                                            </span>
                                            <span
                                                *ngIf=' form.get("confirm_email")?.value && (form.get("email")?.value !== form.get("confirm_email")?.value)'
                                                class='control-error diferent-email'
                                            >
                                            OS EMAILS PRECISAM SER IGUAIS
                                            </span>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'phone'">
                                            <app-input
                                                [id]='input.component'
                                                [name]='input.component'
                                                [labelName]='input.placeholder'
                                                [placeholder]='input.placeholder'
                                                inputMask='(00) 00000-0000'
                                                [form]='form'
                                            ></app-input>
                                            <span
                                                *ngIf='form.get("phone")?.value && form.get("phone")?.value.length < 11'
                                                class='control-error diferent-email'
                                            >
                                            POR FAVOR, INSIRA UM TELEFONE VÁLIDO
                                            </span>
                                        </div>
                                        <div class="contact" *ngIf="input.component === 'Como você prefere receber o nosso contato?'">
                                            <h3>{{input.component}}</h3>
                                            <div class="options">
                                                <div class="option" *ngFor="let option of input.children let id = index">
                                                    <app-radio
                                                        [id]="option.component + '-' + id"
                                                        name="message"
                                                        [labelName]="option.placeholder"
                                                        [value]="option.value"
                                                        [form]='form'
                                                        [changeModel]="changeRadio"
                                                    ></app-radio>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="field modelo" *ngIf="input.component === 'profession'">
                                            <app-select-autocomplete
                                                [id]='input.component'
                                                [name]='input.component'
                                                [placeholder]='input.placeholder'
                                                labelName=''
                                                [disabled]='false'
                                                [itemsList]='professions'
                                                [form]='form'
                                                [showLoading]='showLoading'
                                                (search)='onSearchProfession($event)'
                                                (select)='onSelectProfession($event)'
                                            ></app-select-autocomplete>
                                            <span
                                                class="custom-tooltip btn-open"
                                                (click)="openModalProfessions()"
                                            >i</span>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'salaryRange'">
                                            <app-select
                                                [id]='input.component'
                                                [name]='input.component'
                                                [labelName]='input.placeholder'
                                                [placeholder]='input.placeholder'
                                                [options]='salaryRange'
                                                inputModel=''
                                                [form]='form'
                                            ></app-select>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'zipCode'">
                                            <app-input
                                                [id]='input.component'
                                                [name]='input.component'
                                                [labelName]='this.helpers.removeTags(input.placeholder)'
                                                [placeholder]='this.helpers.removeTags(input.placeholder)'
                                                [form]='form'
                                                inputMask='00000-000'
                                                (emmitEvent)='getAddressByZipCode($event)'
                                            ></app-input>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'state'">
                                            <app-select
                                                [id]='input.component'
                                                [name]='input.component'
                                                [options]="ufOptions"
                                                [labelName]='input.placeholder'
                                                [placeholder]='input.placeholder'
                                                [form]="form"
                                                (emmitEvent)="getCities()"
                                                [disabled]='disabledAddress'
                                            ></app-select>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'city'">
                                            <app-select
                                                *ngIf="_reload"
                                                [id]='input.component'
                                                [name]='input.component'
                                                [labelName]='input.placeholder'
                                                [placeholder]='input.placeholder'
                                                [options]="cityOptions"
                                                [form]="form"
                                                [disabled]='disabledAddress || !cityOptions.length'
                                            ></app-select>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'neighborhood'">
                                            <app-input
                                                [id]='input.component'
                                                [name]='input.component'
                                                [labelName]='input.placeholder'
                                                [placeholder]='input.placeholder'
                                                [form]='form'
                                                type="hidden"
                                            ></app-input>
                                        </div>
                                        <div class="field" [class]="form.get('interest1Title')?.value === 'Automóvel' ? 'car-app-input' : ''"  *ngIf="input.component === 'interest1Title' && slug === 'auto'">
                                            <app-select
                                                [id]='input.component'
                                                [name]='input.component'
                                                [placeholder]='input.placeholder'
                                                [options]='types'
                                                inputModel=''
                                                [form]='form'
                                                (change)='getYears()'
                                            ></app-select>
                                            <div class="mt-2 mx-2 d-flex justify-content-start" *ngIf="form.get('interest1Title')?.value === 'Automóvel'">
                                                <p class="car-text">
                                                    O veículo é ultilizado em Apps de transporte? (Ex: Uber ou 99)
                                                </p>
                                            </div>
                                            <div class="mt-2 mx-2 d-flex justify-content-start" *ngIf="form.get('interest1Title')?.value === 'Automóvel'">
                                                <div class="options options-auto justify-content-center">
                                                    <div class="option ps-0">
                                                        <div class="control-radio">
                                                            <label for="isAppCar">
                                                                <input
                                                                    title="isAppCar"
                                                                    type="radio"
                                                                    id='isAppCar'
                                                                    name="changeItem1"
                                                                    value="'Sim'"

                                                                    (click)="openModalClearForm()"
                                                                >
                                                                <div class="checkmark"></div>
                                                                <span>
                                                                    Sim
                                                                </span>
                                                            </label>

                                                        </div>
                                                    </div>
                                                    <div class="option">
                                                        <app-radio
                                                            id='isNotAppCar'
                                                            name="changeItem"
                                                            labelName='Não'
                                                            [value]="'Não'"
                                                            [form]='form'
                                                        ></app-radio>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'nationality'">
                                            <app-select
                                                [id]='input.component'
                                                [name]='input.component'
                                                [placeholder]='input.placeholder'
                                                [options]='nationalities'
                                                inputModel=''
                                                [form]='form'
                                                (change)='getYears()'
                                            ></app-select>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'interest6Title'">
                                            <app-select
                                                [id]='input.component'
                                                [name]='input.component'
                                                [placeholder]='input.placeholder'
                                                [options]='years'
                                                [disabled]='years.length <= 1'
                                                inputModel=''
                                                [form]='form'
                                                (change)='form.get("interest3Title")?.setValue("")'
                                            ></app-select>
                                        </div>
                                        <div class="field modelo" *ngIf="input.component === 'interest3Title'">
                                            <app-select-autocomplete
                                                [id]='input.component'
                                                [name]='input.component'
                                                [placeholder]='input.placeholder'
                                                [disabled]='!form.get("interest6Title")?.value'
                                                [itemsList]='modelos'
                                                [form]='form'
                                                [showLoading]='showLoading'
                                                (search)='onSearch($event)'
                                                (select)='onSelect($event)'
                                            ></app-select-autocomplete>
                                            <span
                                                class="custom-tooltip btn-open"
                                                (click)="openModalModel()"
                                            >i</span>
                                        </div>
                                        <div class="field" *ngIf="input.component === 'interest5Title'">
                                            <app-input
                                                [id]='input.component'
                                                [name]='input.component'
                                                [labelName]='input.placeholder'
                                                [placeholder]='input.placeholder'
                                                [form]='form'
                                                [max]='8'
                                            ></app-input>
                                            <span
                                                *ngIf='form.get("interest6Title")?.value === "0"'
                                                class='control-error plate'
                                            >

                                            </span>
                                            <span
                                                *ngIf='form.get("interest6Title")?.value && (form.get("interest6Title")?.value !== "0") && (!form.get("interest5Title")?.value)'
                                                class='control-error diferent-email'
                                            >
                                            CAMPO OBRIGATÓRIO
                                            </span>
                                        </div>
                                        <div class="row" >
                                            <div class="col-12" *ngIf="input.component === 'safeType'">
                                                <div class="options justify-content-start">
                                                    <div class="option ps-0">
                                                        <app-radio
                                                            id='safeNew'
                                                            name="safeType"
                                                            labelName='Seguro Novo'
                                                            [value]="'Seguro Novo'"
                                                            [form]='form'
                                                        ></app-radio>
                                                    </div>
                                                    <div class="option">
                                                        <app-radio
                                                            id='safeRenew'
                                                            name="safeType"
                                                            labelName='Renovação de Seguro'
                                                            [value]="'Renovação de Seguro'"
                                                            [form]='form'
                                                        ></app-radio>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="option mb-2 interesse" *ngIf="input.component === 'interest2Title'">
                                                <app-checkbox
                                                    id='interest2Title'
                                                    name="interest2Title"
                                                    [labelName]='input.placeholder'
                                                    [value]="input.value"
                                                    [form]='form'
                                                ></app-checkbox>
                                            </div>
                                            <div class="col-12 mb-3 " *ngIf="input.component === 'consent'">
                                                <div class="option d-flex">
                                                    <app-checkbox
                                                        style="width: 95%"
                                                        [id]="input.component"
                                                        [name]="input.component"
                                                        [form]='form'
                                                    >
                                                        <div
                                                            class="label-checkbox"
                                                            [innerHTML]="input.placeholder | htmlParse"
                                                        ></div>
                                                    </app-checkbox>
                                                    <span
                                                        style="width: 5%"
                                                        class="custom-tooltip btn-open "
                                                        (click)="openModal()"
                                                    >i</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="contact residence-text" *ngIf="input.component === 'interest1Title' && slug !== 'auto'">
                                            <h3>{{input.placeholder}}</h3>
                                            <div class="options-residence">
                                                <div class="option" *ngFor="let option of input.children">
                                                    <app-radio
                                                        [id]="option.component"
                                                        name="interest1Title"
                                                        [labelName]="option.placeholder"
                                                        [value]="option.value"
                                                        [form]='form'
                                                        [changeModel]="changeRadio"
                                                    ></app-radio>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field" *ngIf="input.component === 'multipleCheckbox'">
                                            <app-select
                                                [id]='input.component'
                                                [name]='input.component'
                                                [placeholder]='this.helpers.removeTags(input.placeholder)'
                                                [options]='protectOptions'
                                                [disabled]='protectOptions.length <= 1'
                                                inputModel=''
                                                [form]='form'
                                            ></app-select>
                                        </div>
                                    </div>

                                </div>

                                <div *ngIf="!steps.button || !steps.button.text || !steps.button.background || !steps.button.text_color " class="action-button">
                                    <button *ngIf="component.data.form.steps.length > i+1 && component.data.form.steps.length !== i+2" class="next-btn next-btn1" type="button" [disabled]="disabled" (click)="nextStep('PRÓXIMO')">
                                        Próximo
                                    </button>
                                    <button *ngIf="component.data.form.steps.length === i+2" class="submit-btn mb-3" type="submit" [disabled]="disabled">
                                        QUERO UMA COTAÇÃO
                                    </button>
                                </div>

                                <div *ngIf="(steps ||steps.button || steps.button.text || steps.button.background || steps.button.text_color) || (component.data.form.steps.length > i+1 && step > 1)" class="action-button">
                                    <button *ngIf="steps && steps.button && steps.button.text && steps.button.background && steps.button.text_color "
                                            [class]="steps?.button?.text === 'PRÓXIMO' ? 'next-btn next-btn1' : 'submit-btn mb-3' " type="button"
                                            [disabled]="disabled" [style.backgound-color]="steps?.button?.background"
                                            [style.color]="steps?.button?.text_color" (click)="nextStep(steps?.button?.text)" >
                                        {{steps?.button?.text}}
                                    </button>
                                    <button *ngIf="component.data.form.steps.length > i+1 && step > 1" class="back-step mt-3" type="button" (click)="backStep()">
                                        voltar
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="formFeedback && fullRequest">
                                <h5 *ngIf="formFeedback?.title && !messageFeedback" class="title" [innerHTML]="formFeedback.title | htmlParse"></h5>

                                <h5 *ngIf='messageFeedback'>
                                    <p style='text-align: center;'>
                                        <span style="color:#122b54" [innerHTML]="messageFeedback | htmlParse"></span>
                                    </p>
                                </h5>
                            </div>
                        </div>
                    </form>
                </div>
            </app-banner-form>
        </div>
        <!-- end form -->

        <!-- anchor-->


        <div id='anchor-menu' class='wrapper-anchor container' *ngIf="component.handle == 'anchor_menu' && !isMobile" [ngClass]="{'d-none': isScrolled}">
            <p>O que deseja saber?</p>

            <div  class='wrapper-anchor-menus'>
                <div class='item' *ngFor="let anchor of component.menu let i = index">
                    <a [routerLink]='"."' [fragment]="anchor?.fragment" [class.active]="(activeFragment | async) === anchor?.fragment">{{ anchor?.title }}</a>
                </div>
            </div>
        </div>

        <div id='anchor-mobile' class='wrapper-anchor container anchor-mobile' *ngIf="component.handle == 'anchor_menu' && isMobile" [ngClass]="{'active': activeItems}">
            <p (click)="showItems()">O que deseja saber?</p>

            <div class='wrapper-anchor-menus ' [ngClass]="activeItems ? 'd-flex' : 'd-none'">
                <div class='item' *ngFor="let anchor of component.menu let i = index">
                    <a [routerLink]='"."' [fragment]="anchor?.fragment" [class.active]="(activeFragment | async) === anchor?.fragment" (click)='closeItems()'>{{ anchor?.title }}</a>
                </div>
            </div>
        </div>

        <!-- end anchor-->

        <!-- auto -->
        <div *ngIf="component.handle === 'benefits'" [id]='component.fragment ? component.fragment : ""'>
            <app-banner-text [img]="component.data.image_flashy" [title]="component.title" [services]="component.data.benefits_items"></app-banner-text>
        </div>

        <!-- end auto -->

        <!-- life -->
        <div *ngIf="component.handle == 'tab_section'" class="pt-5" [id]='component.fragment ? component.fragment : ""'>
            <div class="wrapper-description">
                <div class="wrapper-container">
                    <div class="container">
                        <div class="text-life">
                            <h2 class="title-section" [innerHTML]="component.data.text | htmlParse"></h2>
                            <p class="description-section text-center" [innerHTML]="component.data.description | htmlParse"></p>
                        </div>
                    </div>
                </div>
            </div>
            <app-tabs>
                <app-tab *ngFor="let tab of component.data.tab let i = index" [tabTitle]="tab.text" [active]="i == 0 ? true : false">
                    <div style="margin-bottom: 60px;"></div>

                    <div *ngFor=" let block of tab.block" class="safe" style="margin-top: 0;" [id]='block.fragment ? block.fragment : ""'>
                        <div *ngIf="block.handle === 'card_section'">
                            <div class="wrapper-container">
                                <div class="container">
                                    <h2 class="title-section mb-5" [innerHTML]="block.data.text"></h2>
                                    <div class="row justify-content-center">
                                        <div class="col-6 col-md-3 mb-4 mx-auto mx-md-0" *ngFor="let card of block.data.cards">
                                            <app-card classCustom="" [data]="card" [legalText]="block.data.legal_text" ></app-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </app-tab>
            </app-tabs>
        </div>
        <!-- end life -->

        <div class="yellow-olympic-section" *ngIf="component.handle == 'block_section' || component.handle == 'benefits'">
            <app-olimpycs></app-olimpycs>
        </div>

        <!-- evidence -->
        <div *ngIf="component.handle == 'testimonial_section'" class='wrapper-evidence' [id]='component.fragment ? component.fragment : ""'>
            <div class='wrapper-slider container'>
                <div class='title-section'>
                    <p [innerHTML]="component.side_text | htmlParse">
                </div>

                <div class='slider-evidence'>
                    <ngx-slick-carousel id='slide-testimonial' class="carousel my-carousel" #slickModal="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let card of component.testimonial_card let i = index">
                            <div class='item'>
                                <p class='title'><strong>{{ card.card_title }}</strong></p>

                                <div class='item-card'>
                                    <p class='description'>
                                        <img class='open-quotation' src='/assets/images/icon-open-quotation-marks.png' alt=''>
                                        {{ card.card_message }}
                                        <img class='close-quotation' src='/assets/images/icon-close-quotation-marks.png' alt=''>
                                    </p>

                                    <div class='author'>
                                        <p><strong>{{ card.card_autor }}</strong></p>
                                        <p>{{ card.card_date }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngx-slick-carousel>

                    <p class='obs_text' [innerHTML]="component.obs_text | htmlParse">
                </div>
            </div>

<!--            [routerLink]="component.button.link_url"-->
            <a (click)="goTop()" class="buttonChanger">
                {{ component.button.text }}
            </a>
        </div>
        <!-- end evidence -->

        <!-- comum -->
        <div class="toggle-safes" *ngIf="(component.handle == 'banners') && (partner !== 'renovacao')" [id]='component.fragment ? component.fragment : ""'>
            <app-toggle-safe
                [data]="component.data"
                [img]="component.data.image"
                [title]="component.data.text"
                [text]="component.data.description"
                [link]="component.data.button.link_url.split(splitPoint)[1]"
                [isEven]="component.image_direction == 'right' ? true : false">
            </app-toggle-safe>
        </div>
        <div *ngIf="component.handle === 'card_section'" [id]='component.fragment ? component.fragment : ""'>
            <div *ngIf="component.type === 'circle'">
                <app-assists [data]="component.data"></app-assists>
            </div>

            <div *ngIf="component.type === 'box'">
                <app-insurances [data]="component.data"></app-insurances>
            </div>
        </div>
        <!-- end comum -->
    </div>
    <!-- renovacao -->
    <div *ngIf="partner === 'renovacao'">
        <app-toggle-safe-renovation
            [data1]="renovacaoData[0]"
            [img1]="'/assets/images/EsquerdoBanner.jpg'"
            [title1]="renovacaoData[0].text"
            [text1]="renovacaoData[0].description"
            [link1]="renovacaoData[0].button.link_url.split(splitPoint)[1]"
            [isEven1]="false"
            [textFooter1]="renovacaoData[0].sub_description"
            [titleFooter1]="renovacaoData[0].sub_text"
            [data2]="renovacaoData[1]"
            [img2]="'/assets/images/DireitoBanner.jpg'"
            [title2]="renovacaoData[1].text"
            [text2]="renovacaoData[1].description"
            [link2]="renovacaoData[1].button.link_url.split(splitPoint)[1]"
            [isEven2]="true"
            [textFooter2]="renovacaoData[1].sub_description"
            [titleFooter2]="renovacaoData[1].sub_text">
        </app-toggle-safe-renovation>
    </div>
    <!-- end renovacao -->
</div>
